<template>
  <div>
    <div class="login">
      <div class="login_title">登录</div>
      <div class="login_from">
        <div><van-icon name="manager" /><input type="text" /></div>
        <div><input type="password" /></div>
      </div>
      <div class="onColor">
        <van-button color="linear-gradient(to right, #fed001, #febd03)" @click="pushHome">
          登录
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  methods:{
    pushHome(){
      this.$router.push({name:'/CalYl'});
    }
  }
};
</script>
<style>
.login_title {
  margin: 80px 0 50px 40px;
  font-size: 22px;
}
.login_from {
  position: relative;
  width: 86%;
  height: 300px;
  margin: 30px 7%;
  box-shadow: 0px 0px 15px #e9f0f6;
  border-radius: 15px;
}
.login_from div {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.login_from input {
  padding-left: 25px;
  border: none;
  font-size: 17px;
}
.login_from div .icon {
  position: absolute;
  top: 7px;
}
.onColor {
  margin-top: 45px;
  text-align: center;
}
.onColor span{
  color: #000;
  letter-spacing:8px;
  font-weight: 700;
  font-size: 17px;
}
.onColor .van-button{
  width: 265px;
  border-radius: 12px;
}
</style>